.ReviewAndSend {
	display: flex;
	flex-direction: column;
	align-items: center;
	// position: relative;
	// bottom: 40px;
	gap: 8px;
	&__money-text {
		display: flex;
		justify-content: center;
		margin-top: 25px;
	}
	&__money-text--input {
		border: none;
		outline: none;
		padding-bottom: 8px;
		min-width: 250px;
		color: var(--dividerPrimaryColor);
		background-color: var(--containerPrimaryActiveColor);
		border-bottom: 1px solid var(--contentLightToDark);
	}
}
.ReviewAndSend__send {
	font-size: 26px;
	text-align: center;
	white-space: nowrap;
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--contentPrimaryActiveColor);
}
.ReviewAndSend__recipient-card {
	position: relative;
	padding: 8px 4px 4px 4px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
}
.ReviewAndSend__recipient-initials {
	font-size: 48px;
	width: 96px;
	height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: var(--color-bg-80-light);
	color: var(--dividerPrimaryColor);
	background-color: var(--controlActiveColor);
}
.ReviewAndSend__recipient-name {
	padding-top: 16px;
	white-space: nowrap;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--contentPrimaryActiveColor);
}
.ReviewAndSend__recipient-phone {
	font-size: var(--font-size-2);
	color: var(--contentPrimaryActiveColor);
}
.ReviewAndSend__notification {
	padding-top: 35px;
	font-size: var(--font-size-2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	color: var(--body-text-secondary-color);
}
.ReviewAndSend__send {
	padding: 8px 0px;
	.button__large {
		min-width: 300px;
		padding: 16px;
		display: flex;
		justify-content: center;
	}
}

.ReviewAndSend__money-text--input::placeholder {
	color: var(--dividerPrimaryColor);
}
