.loading-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&__heading {
		color: var(--contentPrimaryActiveColor);
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px; /* 160% */
	}
	height: 100%;
	text-align: center;
	&__loader {
		width: 80px;
		height: 80px;
	}
	&__desp {
		margin: 8px 0px;
		color: var(--dividerPrimaryColor);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
	&__resend-wrapper {
		padding: 16px 24px;
		border-radius: 8px;
		border: 1px;
		border: 1px solid var(--contentPrimaryActiveColor);
		color: var(--contentPrimaryActiveColor);
		font-size: 14px;
	}
	&__resend-btn {
		color: var(--color-blue);
		cursor: pointer;
		padding-left: 4px;
	}
}

.loader-waiting {
	border: 2px solid var(--contentPrimaryActiveColor);
	border-radius: 50%;
	border-top: 0px solid #f3f3f3;
	border-left: 0px solid #f3f3f3;
	width: 96px;
	height: 96px;
	animation: spin 1s linear infinite;
	position: absolute;
	top: 9px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.image-box {
	display: flex;
	justify-content: center;
	align-items: center;
}
