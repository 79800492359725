html[data-theme='dark'] {
	/* Container Colors */
	--containerPageColor: rgb(30, 32, 33);
	--containerPrimaryColor: rgb(30, 32, 33);
	--containerPrimaryEnabledColor: rgb(42, 44, 46);
	--containerPrimaryHoverColor: rgb(49, 52, 55);
	--primary-button-color: rgb(255, 225, 38);
	--containerPrimaryActiveColor: rgb(59, 62, 65);
	--containerPrimarySelectedColor: rgb(25, 42, 137);
	--containerSecondaryEnabledColor: rgb(49, 52, 55);
	--containerSecondaryHoverColor: rgb(42, 44, 46);
	--containerSecondaryActiveColor: rgb(59, 62, 65);
	--containerSecondarySelectedColor: rgb(35, 51, 158);
	--overlayColor: rgba(42, 44, 46, 0.6);

	/* Control Colors */
	--controlEnabledColor: rgb(68, 72, 75);
	--controlHoverColor: rgb(78, 81, 85);
	--controlActiveColor: rgb(30, 32, 33);

	/* Divider Colors */
	--dividerPrimaryColor: rgb(147, 153, 159);
	--dividerSecondaryColor: rgb(59, 62, 65);
	--dividerInverseColor: rgb(42, 44, 46);

	/* Brand Colors */
	--brandPrimaryColor: rgb(6, 24, 95);
	--brandSecondaryColor: rgb(8, 92, 229);
	--brandTertiaryColor: rgb(118, 220, 253);
	--brandGradientLightColor: linear-gradient(
		135deg,
		rgb(232, 247, 247) 0%,
		rgb(118, 220, 253) 100%
	);
	--brandGradientMediumColor: linear-gradient(
		135deg,
		rgb(118, 220, 253) 0%,
		rgb(8, 92, 229) 100%
	);
	--brandGradientDarkColor: linear-gradient(
		135deg,
		rgb(8, 92, 229) 0%,
		rgb(6, 24, 95) 100%
	);
	--brandOnPrimaryColor: rgb(255, 255, 255);
	--brandOnSecondaryColor: rgb(255, 255, 255);
	--brandOnTertiaryColor: rgb(6, 24, 95);
	--brandOnGradientLightColor: rgb(6, 24, 95);
	--brandOnGradientDarkColor: rgb(255, 255, 255);

	/* Content Colors */
	--contentBrandEnabledColor: rgb(118, 180, 248);
	--contentBrandHoverColor: rgb(134, 193, 248);
	--contentBrandActiveColor: rgb(163, 212, 255);
	--contentPrimaryEnabledColor: rgb(231, 236, 240);
	--contentPrimaryHoverColor: rgb(240, 245, 249);
	--contentPrimaryActiveColor: rgb(255, 255, 255);
	--contentSecondaryEnabledColor: rgb(169, 176, 182);
	--contentSecondaryHoverColor: rgb(182, 187, 192);
	--contentSecondaryActiveColor: rgb(201, 206, 211);
	--contentPositiveEnabledColor: rgb(95, 196, 69);
	--contentPositiveHoverColor: rgb(106, 209, 80);
	--contentPositiveActiveColor: rgb(136, 226, 110);
	--contentNegativeEnabledColor: rgb(250, 145, 118);
	--contentNegativeHoverColor: rgb(248, 164, 142);
	--contentNegativeActiveColor: rgb(251, 191, 174);
	--contentInverseEnabledColor: rgb(42, 44, 46);
	--contentInverseHoverColor: rgb(49, 52, 55);
	--contentInverseActiveColor: rgb(59, 62, 65);
	--contentOnBrandEnabledColor: rgb(42, 44, 46);
	--contentOnBrandHoverColor: rgb(42, 44, 46);
	--contentOnBrandActiveColor: rgb(42, 44, 46);
	--contentOnPrimaryEnabledColor: rgb(42, 44, 46);
	--contentOnPrimaryHoverColor: rgb(42, 44, 46);
	--contentOnPrimaryActiveColor: rgb(42, 44, 46);
	--contentOnSecondaryEnabledColor: rgb(42, 44, 46);
	--contentOnSecondaryHoverColor: rgb(42, 44, 46);
	--contentOnSecondaryActiveColor: rgb(42, 44, 46);
	--contentOnPositiveEnabledColor: rgb(42, 44, 46);
	--contentOnPositiveHoverColor: rgb(42, 44, 46);
	--contentOnPositiveActiveColor: rgb(42, 44, 46);
	--contentOnNegativeEnabledColor: rgb(42, 44, 46);
	--contentOnNegativeHoverColor: rgb(42, 44, 46);
	--contentOnNegativeActiveColor: rgb(42, 44, 46);
	--contentOnInverseEnabledColor: rgb(255, 255, 255);
	--contentOnInverseHoverColor: rgb(255, 255, 255);
	--contentOnInverseActiveColor: rgb(255, 255, 255);
	--link-button-text-focused-color: rgb(40, 155, 244);
	--contentLightToDark: rgb(46, 46, 46);
	--body-text-secondary-color: rgb(147, 153, 159);

	/* Interactive Colors */
	--interactiveFocusOuterColor: rgb(118, 180, 248);
	--interactiveFocusInnerColor: rgb(42, 44, 46);
	--interactiveHighlightColor: rgb(27, 67, 80);
	--interactiveVisitedColor: rgb(210, 152, 246);

	/* Font Family */
	--fontFamilyPrimary: 'Roboto Flex', Roboto, Helvetica, Arial, sans-serif;

	/* Font Styles */
	--fontMicroRegular: 400 10px/12px var(--fontFamilyPrimary);
	--fontMicroMedium: 500 10px/12px var(--fontFamilyPrimary);
	--fontMicroBold: 700 10px/12px var(--fontFamilyPrimary);
	--fontHelperRegular: 400 12px/16px var(--fontFamilyPrimary);
	--fontHelperMedium: 500 12px/16px var(--fontFamilyPrimary);
	--fontHelperBold: 700 12px/16px var(--fontFamilyPrimary);
	--fontBodyRegular1: 400 14px/20px var(--fontFamilyPrimary);
	--fontBodyRegular2: 400 16px/24px var(--fontFamilyPrimary);
	--fontBodyMedium1: 500 14px/20px var(--fontFamilyPrimary);
	--fontBodyMedium2: 500 16px/24px var(--fontFamilyPrimary);
	--fontBodyBold1: 700 14px/20px var(--fontFamilyPrimary);
	--fontBodyBold2: 700 16px/24px var(--fontFamilyPrimary);
	--fontBodyRegularItalic1: 400 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyRegularItalic2: 400 italic 16px/24px var(--fontFamilyPrimary);
	--fontBodyMediumItalic1: 500 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyMediumItalic2: 500 italic 16px/24px var(--fontFamilyPrimary);
	--fontBodyBoldItalic1: 700 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyBoldItalic2: 700 italic 16px/24px var(--fontFamilyPrimary);
	--fontCodeRegular1: 400 14px/20px 'Roboto Mono', monospace;
	--fontCodeRegular2: 400 16px/24px 'Roboto Mono', monospace;
	--fontDisplayLight1: 300 42px/52px var(--fontFamilyPrimary);
	--fontDisplayLight2: 300 54px/64px var(--fontFamilyPrimary);
	--fontDisplayLight3: 300 92px/108px var(--fontFamilyPrimary);
	--fontHeadingRegular1: 400 14px/20px var(--fontFamilyPrimary);
	--fontHeadingRegular2: 400 16px/24px var(--fontFamilyPrimary);
	--fontHeadingRegular3: 400 20px/28px var(--fontFamilyPrimary);
	--fontHeadingRegular4: 400 28px/36px var(--fontFamilyPrimary);
	--fontHeadingRegular5: 400 32px/40px var(--fontFamilyPrimary);
	--fontHeadingRegular6: 400 36px/44px var(--fontFamilyPrimary);
	--fontHeadingMedium1: 500 14px/20px var(--fontFamilyPrimary);
	--fontHeadingMedium2: 500 16px/24px var(--fontFamilyPrimary);
	--fontHeadingMedium3: 500 20px/28px var(--fontFamilyPrimary);
	--fontHeadingMedium4: 500 28px/36px var(--fontFamilyPrimary);
	--fontHeadingMedium5: 500 32px/40px var(--fontFamilyPrimary);
	--fontHeadingMedium6: 500 36px/44px var(--fontFamilyPrimary);
	--fontHeadingBold1: 700 14px/20px var(--fontFamilyPrimary);
	--fontHeadingBold2: 700 16px/24px var(--fontFamilyPrimary);
	--fontHeadingBold3: 700 20px/28px var(--fontFamilyPrimary);
	--fontHeadingBold4: 700 28px/36px var(--fontFamilyPrimary);
	--fontHeadingBold5: 700 32px/40px var(--fontFamilyPrimary);
	--fontHeadingBold6: 700 36px/44px var(--fontFamilyPrimary);

	/* Opacity */
	--opacityDisabled: 0.3;

	/* Shadow */
	--shadowFocus: 0 0 0 1px rgb(42, 44, 46), 0 0 0 3px rgb(118, 180, 248);
}

/* Dark Theme Styles */

// Write your dark theme styles here using the variables defined above
