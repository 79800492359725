.verification {
	&__success {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__success-img {
		width: 80px;
	}
	&__success_img-wrapper {
		height: 80px;
		width: 80px;
	}
	&__success-message {
		font-size: 24px;
		font-weight: 600;
		text-align: center;
		line-height: var(--line-height-7);
		color: var(--contentPrimaryActiveColor);
	}
	&__success-description {
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		line-height: var(--line-height-3);
	}
	&__sub-heading {
		color: var(--dividerPrimaryColor);
		text-align: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		padding: 8px 0px;
	}
}
