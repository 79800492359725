// Import variables
@import '../variables/border-radius';
@import '../variables/colors';
@import '../variables/font-size';
@import '../variables/line-heights';
@import '../variables/margin';
@import '../variables/padding';
@import 'remixicon/fonts/remixicon.css';

@import '../variables/dark-theme';
@import '../variables/light-theme';

[class^='ri-'],
[class*=' ri-'] {
	color: var(--contentPrimaryActiveColor);
}

.Main {
	display: flex;
	justify-content: center;

	&__bodyWrapper {
		position: relative;
		width: 100%;
		background-color: var(--containerPageColor);
	}
}
