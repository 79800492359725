* {
	font-weight: lighter;
}
.EnterAmount {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	input::-webkit-inner-spin-button {
		display: none;
	}
}
.EnterAmount__sendTo {
	font-size: var(--font-size-5);
	text-align: center;
	white-space: nowrap;
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--contentPrimaryActiveColor);
}
.EnterAmount__amount {
	display: flex;
	align-items: flex-start;

	color: var(--contentPrimaryActiveColor);
}
.EnterAmount__input-amount {
	width: 50px;
	font-size: 82px;
	font-weight: lighter;
	border: none;
	text-align: center;
	background-color: var(--containerPrimaryActiveColor);
	color: var(--contentPrimaryActiveColor);

	&:focus {
		outline: none;
		border: none;
	}
}
.EnterAmount__other-info {
	text-align: center;
	font-size: var(--font-size-2);
	color: var(--link-button-text-focused-color);
	font-size: 14px;
	line-height: 21px;
	padding-bottom: 30px;
}
.EnterAmount__review {
	padding: 24px;
	.button__large {
		min-width: 300px;
		padding: 16px;
		display: flex;
		justify-content: center;
	}
}
