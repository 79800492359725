.select-recipeint-wrapper {
	height: 100%;
}
.SelectRecipient {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
}
.SelectRecipient__title {
	padding: 8px 0;
	color: var(--contentPrimaryActiveColor);
	border-bottom: 1px solid var(--contentLightToDark);
	font-weight: 500;
}
.SelectRecipient__recipient-list {
	max-height: 280px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 4px;
	overflow-y: overlay;
	overflow-x: hidden;
}
.SelectRecipient__recipient-card {
	padding: 8px 4px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		background: var(--contentInverseHoverColor);
	}
}
.SelectRecipient__recipient-initials {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: var(--controlActiveColor);
	font-size: var(--font-size-5);
	margin-bottom: 4px;
	color: var(--dividerPrimaryColor);
}
.SelectRecipient__recipient-name {
	white-space: nowrap;
	max-width: 110px;
	overflow: hidden;
	font-weight: 500;
	text-overflow: ellipsis;
	color: var(--contentPrimaryActiveColor);
	font-size: 14px;
}
.SelectRecipient__recipient-phone {
	white-space: nowrap;
	max-width: 110px;
	overflow: hidden;
	font-weight: 500;
	text-overflow: ellipsis;
	color: var(--dividerPrimaryColor);
	font-size: 12px;
	margin-top: 4px;
}

.search-input-wrapper {
	display: flex;
	align-items: center;
	border: 1px solid var(--contentLightToDark);
	border-radius: 4px;
	padding: 12px;
}
.search-input {
	border: none;
	width: calc(100% - 40px);
	outline: none;
	font-weight: 500;
	background-color: var(--containerPrimaryActiveColor);
	color: var(--contentPrimaryActiveColor);
}
.search-icon {
	margin-right: 8px;
	color: var(--contentPrimaryActiveColor) !important;
	cursor: pointer;
}
.search-input::placeholder {
	color: var(--dividerPrimaryColor);
}
.Modal__closeButton {
	font-size: 12px !important;
}
