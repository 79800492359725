.SendPayment__modal {
	.Modal__wrapper {
		padding: unset;
		background-color: var(--containerPrimaryActiveColor);
		padding: 16px;
	}
}
.SendPayment__modal--body {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 472px;
	height: 420px;
	padding: 16px 0px;
}
.SendPayment__header {
	display: flex;
	align-items: center;
	gap: 16px;
	border-bottom: 1px solid var(--contentLightToDark);
	padding: 8px 0px 8px 0px;
	color: var(--contentPrimaryActiveColor);
	&--backIcon {
		font-size: var(--font-size-6);
		cursor: pointer;
	}

	&--title {
		width: 100%;
		font-size: var(--font-size-4);
		font-weight: 500;
		color: var(--contentPrimaryActiveColor);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.senderBox {
	padding: 10px;
	border-radius: 8px;
	max-width: 300px;
	min-width: 300px;
	background-color: var(--containerPrimaryColor);
	position: absolute;
	top: 30%;
	right: 50%;
	transform: translate(50%, 50%);
	&__heading {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		color: var(--contentPrimaryActiveColor);
	}
	&__option-box {
		display: flex;
		justify-content: space-around;
		text-align: center;
		align-items: center;
		font-size: 12px;
		color: var(--contentPrimaryActiveColor);
		padding: 12px 12px;
	}
	&__options {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
	}
	&__option-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		border-radius: 8px;
		font-size: 20px;
		background-color: var(--controlEnabledColor);
		width: 36px;
		height: 36px;
		margin-bottom: 4px;
	}
	&__borderbox {
		width: 1px;
		height: 25px;
		background-color: var(--contentPrimaryActiveColor);
	}
}
