.header-container {
	display: flex;
	width: 100%;
	background-color: var(--color-white);
	height: 20vh;
	align-items: center;
	padding: var(--padding) 0;
	justify-content: space-between;
	background-color: var(--color-jungle-green);
	@media only screen and (max-width: 800px) {
		padding: var(--padding) 8px;
	}
}
.logo {
	height: 60px;
	max-height: 60px;
	max-width: 70%;
	object-fit: contain;
}
.header-container-frame {
	display: flex;
	width: 100%;
	background-color: var(--color-white);
	height: 40px;
	align-items: center;
	padding: var(--padding);
	justify-content: space-between;
}
.logo-frame {
	height: 30px;
	max-height: 60px;
	max-width: 70%;
	object-fit: contain;
}
@media screen and (min-width: 500px) {
	.header-container {
		border-radius: 6px 6px 0px 0px;
	}
}

.skip-steps-wrapper {
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	font-weight: 500;
	font-size: 12px;
	color: var(--color-primary-light);
	border: 1px solid var(--color-primary-light);
	transition: 0.2s ease-in all;
	padding: 8px;
	border-radius: 8px;
	i {
		font-size: 16px;
	}
}
