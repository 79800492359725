.details-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&__heading {
		color: var(--dividerPrimaryColor);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
	&__out-box {
		padding: 8px 0px;
	}
	&__box {
		display: flex;
		width: 100%;
	}
	&__label {
		width: 50%;
		color: var(--dividerPrimaryColor);
		text-align: right;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		align-items: center;
	}
	&__label-value {
		color: var(--contentPrimaryActiveColor);
		width: 50%;
		text-align: left;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		align-items: center;
		padding-left: 4px;
		flex-wrap: nowrap;
	}
	&__image {
		border-radius: 8px;
		height: 150px;
		width: 150px;
		object-fit: cover;
	}
	&__details {
		font-weight: 600;
		font-size: 16px;
		padding: 8px 8px 4px 8px;
	}
	&__details-fade {
		font-size: 14px;
		padding: 4px 8px 4px 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 330px;
		color: var(--contentPrimaryActiveColor);
	}
	&__btn-box {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__danger-text {
		color: rgb(233, 50, 50);
		font-size: 14px;
	}
	&__reject-wrapper {
		text-align: center;
	}
	&__recject-icon {
		font-size: 120px;
		color: #db6b46;
	}
	&__reject-heading {
		color: var(--contentPrimaryActiveColor);
		font-size: 20px;
		line-height: 32px;
		font-weight: 500;
	}
	&__reject-subheading {
		color: var(--dividerPrimaryColor);
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
	}
}
.btn-wrapper {
	padding: 10px 4px 0px 4px;
}

.cancel_btn-box-border {
	border: 1px solid var(--contentLightToDark);
}

.btn-details {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
}
