.skelton-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	background: var(--color-bg-100-light);
	&__body {
		display: flex;
		height: 100%;
	}
	&__footer {
		width: 100%;
		height: 48px;
		background-color: var(--color-bg-100-light);
		z-index: 99;
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}
@media screen and (min-width: 500px) {
	.skelton-wrapper {
		width: 100%;
		// max-width: 768px;
		// max-width: 420px;
		// border: 2px solid hsla(0, 0, 0, 0.18);
		// border-radius: 6px;
	}
}
