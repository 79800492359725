html[data-theme='light'] {
	/* Colors */
	--containerPageColor: rgb(231, 236, 240); /* Light mode color */
	--primary-button-color: rgb(255, 225, 38);
	--containerPrimaryColor: rgb(255, 255, 255);
	--containerPrimaryHoverColor: rgb(240, 245, 249);
	--containerPrimaryActiveColor: rgb(255, 255, 255);
	--containerPrimarySelectedColor: rgb(236, 245, 254);
	--secondary-page-background-color: rgb(230, 230, 230);
	--containerSecondaryColor: rgb(240, 245, 249);
	--containerSecondaryHoverColor: rgb(255, 255, 255);
	--containerSecondaryActiveColor: rgb(231, 236, 240);
	--containerSecondarySelectedColor: rgb(218, 238, 255);
	--overlayColor: rgba(42, 44, 46, 0.6);
	--controlEnabledColor: rgb(211, 216, 220);
	--controlHoverColor: rgb(201, 206, 211);
	--controlActiveColor: rgb(230, 230, 230);
	--dividerPrimaryColor: rgb(112, 117, 122);
	--dividerSecondaryColor: rgb(231, 236, 240);
	--dividerInverseColor: rgb(255, 255, 255);
	--brandPrimaryColor: rgb(6, 24, 95);
	--brandSecondaryColor: rgb(8, 92, 229);
	--brandTertiaryColor: rgb(118, 220, 253);
	--link-button-text-focused-color: rgb(40, 155, 244);
	--body-text-secondary-color: rgb(112, 112, 112);
	--brandGradientLightColor: linear-gradient(
		135deg,
		rgb(232, 247, 247) 0%,
		rgb(118, 220, 253) 100%
	);
	--brandGradientMediumColor: linear-gradient(
		135deg,
		rgb(118, 220, 253) 0%,
		rgb(8, 92, 229) 100%
	);
	--brandGradientDarkColor: linear-gradient(
		135deg,
		rgb(8, 92, 229) 0%,
		rgb(6, 24, 95) 100%
	);
	--brandOnPrimaryColor: rgb(255, 255, 255);
	--brandOnSecondaryColor: rgb(255, 255, 255);
	--brandOnTertiaryColor: rgb(6, 24, 95);
	--brandOnGradientLightColor: rgb(6, 24, 95);
	--brandOnGradientDarkColor: rgb(255, 255, 255);
	--contentBrandEnabledColor: rgb(8, 92, 229);
	--contentBrandHoverColor: rgb(38, 79, 213);
	--contentBrandActiveColor: rgb(51, 67, 196);
	--contentPrimaryEnabledColor: rgb(42, 44, 46);
	--contentPrimaryHoverColor: rgb(30, 32, 33);
	--contentPrimaryActiveColor: rgb(0, 0, 0);
	--contentSecondaryEnabledColor: rgb(95, 100, 104);
	--contentSecondaryHoverColor: rgb(87, 90, 93);
	--contentSecondaryActiveColor: rgb(78, 81, 85);
	--contentPositiveEnabledColor: rgb(59, 111, 45);
	--contentPositiveHoverColor: rgb(54, 100, 41);
	--contentPositiveActiveColor: rgb(50, 90, 38);
	--contentNegativeEnabledColor: rgb(189, 33, 19);
	--contentNegativeHoverColor: rgb(174, 24, 13);
	--contentNegativeActiveColor: rgb(158, 21, 11);
	--contentInverseEnabledColor: rgb(255, 255, 255);
	--contentInverseHoverColor: rgb(240, 245, 249);
	--contentInverseActiveColor: rgb(231, 236, 240);
	--contentOnBrandEnabledColor: rgb(255, 255, 255);
	--contentOnBrandHoverColor: rgb(255, 255, 255);
	--contentOnBrandActiveColor: rgb(255, 255, 255);
	--contentOnPrimaryEnabledColor: rgb(255, 255, 255);
	--contentOnPrimaryHoverColor: rgb(255, 255, 255);
	--contentOnPrimaryActiveColor: rgb(255, 255, 255);
	--contentOnSecondaryEnabledColor: rgb(255, 255, 255);
	--contentOnSecondaryHoverColor: rgb(255, 255, 255);
	--contentOnSecondaryActiveColor: rgb(255, 255, 255);
	--contentOnPositiveEnabledColor: rgb(255, 255, 255);
	--contentOnPositiveHoverColor: rgb(255, 255, 255);
	--contentOnPositiveActiveColor: rgb(255, 255, 255);
	--contentOnNegativeEnabledColor: rgb(255, 255, 255);
	--contentOnNegativeHoverColor: rgb(255, 255, 255);
	--contentOnNegativeActiveColor: rgb(255, 255, 255);
	--contentOnInverseEnabledColor: rgb(0, 0, 0);
	--contentOnInverseHoverColor: rgb(0, 0, 0);
	--contentOnInverseActiveColor: rgb(0, 0, 0);
	--interactiveFocusOuterColor: rgb(8, 92, 229);
	--interactiveFocusInnerColor: rgb(255, 255, 255);
	--interactiveHighlightColor: rgb(204, 242, 251);
	--interactiveVisitedColor: rgb(150, 30, 231);
	--link-button-text-focused-color: rgb(40, 155, 244);
	--contentLightToDark: rgb(229, 229, 229);

	/* Fonts */
	--fontFamilyPrimary: 'Roboto Flex', Roboto, Helvetica, Arial, sans-serif;
	--fontMicroRegular: 400 10px/12px var(--fontFamilyPrimary);
	--fontMicroMedium: 500 10px/12px var(--fontFamilyPrimary);
	--fontMicroBold: 700 10px/12px var(--fontFamilyPrimary);
	--fontHelperRegular: 400 12px/16px var(--fontFamilyPrimary);
	--fontHelperMedium: 500 12px/16px var(--fontFamilyPrimary);
	--fontHelperBold: 700 12px/16px var(--fontFamilyPrimary);
	--fontBodyRegular1: 400 14px/20px var(--fontFamilyPrimary);
	--fontBodyRegular2: 400 16px/24px var(--fontFamilyPrimary);
	--fontBodyMedium1: 500 14px/20px var(--fontFamilyPrimary);
	--fontBodyMedium2: 500 16px/24px var(--fontFamilyPrimary);
	--fontBodyBold1: 700 14px/20px var(--fontFamilyPrimary);
	--fontBodyBold2: 700 16px/24px var(--fontFamilyPrimary);
	--fontBodyRegularItalic1: 400 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyRegularItalic2: 400 italic 16px/24px var(--fontFamilyPrimary);
	--fontBodyMediumItalic1: 500 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyMediumItalic2: 500 italic 16px/24px var(--fontFamilyPrimary);
	--fontBodyBoldItalic1: 700 italic 14px/20px var(--fontFamilyPrimary);
	--fontBodyBoldItalic2: 700 italic 16px/24px var(--fontFamilyPrimary);
	--fontCodeRegular1: 400 14px/20px 'Roboto Mono', monospace;
	--fontCodeRegular2: 400 16px/24px 'Roboto Mono', monospace;
	--fontDisplayLight1: 300 42px/52px var(--fontFamilyPrimary);
	--fontDisplayLight2: 300 54px/64px var(--fontFamilyPrimary);
	--fontDisplayLight3: 300 92px/108px var(--fontFamilyPrimary);
	--fontHeadingRegular1: 400 14px/20px var(--fontFamilyPrimary);
	--fontHeadingRegular2: 400 16px/24px var(--fontFamilyPrimary);
	--fontHeadingRegular3: 400 20px/28px var(--fontFamilyPrimary);
	--fontHeadingRegular4: 400 28px/36px var(--fontFamilyPrimary);
	--fontHeadingRegular5: 400 32px/40px var(--fontFamilyPrimary);
	--fontHeadingRegular6: 400 36px/44px var(--fontFamilyPrimary);
	--fontHeadingMedium1: 500 14px/20px var(--fontFamilyPrimary);
	--fontHeadingMedium2: 500 16px/24px var(--fontFamilyPrimary);
	--fontHeadingMedium3: 500 20px/28px var(--fontFamilyPrimary);
	--fontHeadingMedium4: 500 28px/36px var(--fontFamilyPrimary);
	--fontHeadingMedium5: 500 32px/40px var(--fontFamilyPrimary);
	--fontHeadingMedium6: 500 36px/44px var(--fontFamilyPrimary);
	--fontHeadingBold1: 700 14px/20px var(--fontFamilyPrimary);
	--fontHeadingBold2: 700 16px/24px var(--fontFamilyPrimary);
	--fontHeadingBold3: 700 20px/28px var(--fontFamilyPrimary);
	--fontHeadingBold4: 700 28px/36px var(--fontFamilyPrimary);
	--fontHeadingBold5: 700 32px/40px var(--fontFamilyPrimary);
	--fontHeadingBold6: 700 36px/44px var(--fontFamilyPrimary);

	/* Opacity */
	--opacityDisabled: 0.3;

	/* Shadows */
	--shadowFocus: 0 0 0 1px var(--interactiveFocusInnerColor),
		0 0 0 3px var(--interactiveFocusOuterColor);
}

/* Other CSS rules and styles can be defined here */
